<template>
  <main class="default-policy">
    <div class="none"></div>
    <div class="opacity-bg"></div>
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style lang="scss" scoped>
  @import "./policy.scss";
</style>
